import React from "react"

import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SupportedSites from "../components/sites"

class SitesPage extends React.Component<{}> {
  render() {
    const pageTitle = "Supported Sites"
    return (
      <Layout>
        <SEO title={pageTitle} />
        <Typography component="h4" variant="h4" gutterBottom>
          {pageTitle}
        </Typography>
        <Paper>
          <SupportedSites />
        </Paper>
      </Layout>
    )
  }
}

export default SitesPage
