import React from "react"

import extractors from "../data/extractors"
import Typography from "@material-ui/core/Typography"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableFooter from "@material-ui/core/TableFooter"
import TableRow from "@material-ui/core/TableRow"

class SupportedSites extends React.Component<Props, object> {
  render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Site Name</TableCell>
            <TableCell>Example URL </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(extractors)
            .filter(entry => entry[1]["url"])
            .map((entry, index) => (
              <TableRow key={index}>
                <TableCell>{entry[0]}</TableCell>
                <TableCell align="left" style={{ maxWidth: 640 }}>
                  <Typography
                    style={{
                      maxWidth: 640,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {entry[1]["url"]}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    )
  }
}

export default SupportedSites
